import { register } from '@netcentric/component-loader';
import config from './batcom-socialmedia.config';

class SocialMedia {
  constructor(el) {
    this.el = el;
    this.init();
  }

  init() {
    this.setRefs();
    this.setEventListeners();
  }

  setRefs() {
    this.copyButton = this.el.querySelector(config.selectors.copyButton);
    this.copyTooltip = this.el.querySelector(config.selectors.copyTooltip);
  }

  setEventListeners() {
    if(this.copyButton) {
      this.copyButton.addEventListener('click', e => this.copyURLToClipboard(e));
    }
  }

  copyURLToClipboard(e) {
    e.preventDefault();
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    this.copyTooltip.classList.remove(config.classes.copyTooltipHidden);

    setTimeout(() => {
      this.copyTooltip.classList.add(config.classes.copyTooltipHidden);
    }, 1000);
  }
}
register({ SocialMedia });
